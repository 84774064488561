import { default as aboutVTih3N9yATMeta } from "/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/about.vue?macro=true";
import { default as _91id_93JhrT5M4ukRMeta } from "/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/books/book/[id].vue?macro=true";
import { default as addg2cJdSXX5aMeta } from "/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/books/book/add.vue?macro=true";
import { default as indexIA6fsWWw2fMeta } from "/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/books/index.vue?macro=true";
import { default as indexcN0l9UP4t8Meta } from "/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/index.vue?macro=true";
import { default as uiS86DcVHfW6Meta } from "/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/scratchpad/ui.vue?macro=true";
export default [
  {
    name: aboutVTih3N9yATMeta?.name ?? "about",
    path: aboutVTih3N9yATMeta?.path ?? "/about",
    meta: aboutVTih3N9yATMeta || {},
    alias: aboutVTih3N9yATMeta?.alias || [],
    redirect: aboutVTih3N9yATMeta?.redirect,
    component: () => import("/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91id_93JhrT5M4ukRMeta?.name ?? "books-book-id",
    path: _91id_93JhrT5M4ukRMeta?.path ?? "/books/book/:id()",
    meta: _91id_93JhrT5M4ukRMeta || {},
    alias: _91id_93JhrT5M4ukRMeta?.alias || [],
    redirect: _91id_93JhrT5M4ukRMeta?.redirect,
    component: () => import("/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/books/book/[id].vue").then(m => m.default || m)
  },
  {
    name: addg2cJdSXX5aMeta?.name ?? "books-book-add",
    path: addg2cJdSXX5aMeta?.path ?? "/books/book/add",
    meta: addg2cJdSXX5aMeta || {},
    alias: addg2cJdSXX5aMeta?.alias || [],
    redirect: addg2cJdSXX5aMeta?.redirect,
    component: () => import("/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/books/book/add.vue").then(m => m.default || m)
  },
  {
    name: indexIA6fsWWw2fMeta?.name ?? "books",
    path: indexIA6fsWWw2fMeta?.path ?? "/books",
    meta: indexIA6fsWWw2fMeta || {},
    alias: indexIA6fsWWw2fMeta?.alias || [],
    redirect: indexIA6fsWWw2fMeta?.redirect,
    component: () => import("/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/books/index.vue").then(m => m.default || m)
  },
  {
    name: indexcN0l9UP4t8Meta?.name ?? "index",
    path: indexcN0l9UP4t8Meta?.path ?? "/",
    meta: indexcN0l9UP4t8Meta || {},
    alias: indexcN0l9UP4t8Meta?.alias || [],
    redirect: indexcN0l9UP4t8Meta?.redirect,
    component: () => import("/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: uiS86DcVHfW6Meta?.name ?? "scratchpad-ui",
    path: uiS86DcVHfW6Meta?.path ?? "/scratchpad/ui",
    meta: uiS86DcVHfW6Meta || {},
    alias: uiS86DcVHfW6Meta?.alias || [],
    redirect: uiS86DcVHfW6Meta?.redirect,
    component: () => import("/Users/fbonin/IdeaProjects/lotto-qc/github/org-rd-fullstack-springboot-nuxt/src/frontend/pages/scratchpad/ui.vue").then(m => m.default || m)
  }
]